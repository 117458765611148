<template>
  <Select v-model="type" @on-change="selectChange">
    <Option
      v-for="item in typeList.filter(t=>!hiddenWhole||t.label!=='全部')"
      :value="item.value"
      :key="item.value"
    >{{ item.label }}</Option>
  </Select>
</template>

<script>
export default {
  props: {
    value: {},
    hiddenWhole: {
      default: false
    },
    classification: {
      default: 'article' // article game
    }
  },
  computed: {
    role() {
      return this.$store.getters.getLaun || 'en';
    }
  },
  data() {
    return {
      type: '',
      typeList: [],
      enTypes: [
        {
          value: 'en',
          label: '全部'
        },
        {
          value: 'enView',
          label: '英文绘本'
        },
        {
          value: 'enVerse',
          label: '英文韵文'
        },
        {
          value: 'enSentence',
          label: '英文句子'
        },
        {
          value: 'enWords',
          label: '英文词卡'
        }
      ],
      chTypes: [
        {
          value: 'cn',
          label: '全部'
        },
        {
          value: 'cnView',
          label: '中文绘本'
        },
        {
          value: 'cnVerse',
          label: '中文韵文'
        },
        {
          value: 'cnWords',
          label: '中文词语'
        },
        // {
        //   value: "cnIdiom",
        //   label: "中文成语"
        // },
        {
          value: 'cnIntensive',
          label: '中文精读'
        }
      ],
      chGameTypes: [
        {
          value: 'cn',
          label: '全部'
        },
        {
          value: 'cnIdiomComb',
          label: '成语接龙'
        },
        {
          value: 'cnFlying',
          label: '飞花令'
        }
      ]
    };
  },
  methods: {
    selectChange() {
      if (!this.type) {
        this.updateValue();
        return;
      }
      this.$emit('input', this.type);
    },
    updateValue() {
      let ind = this.typeList.findIndex((ele) => ele.value === this.value);
      if (ind >= 0) {
        return;
      }
      this.$emit('input', this.typeList[this.hiddenWhole ? 1 : 0].value);
    }
  },
  watch: {
    role: {
      handler() {
        if (this.classification === 'game') {
          if (this.role === 'en') {
            //
          } else {
            this.typeList = this.chGameTypes;
          }
        } else {
          if (this.role === 'en') {
            this.typeList = this.enTypes;
          } else {
            this.typeList = this.chTypes;
          }
        }
        this.updateValue();
      },
      immediate: true
    },
    value: {
      handler() {
        this.type = this.value;
      },
      immediate: true
    }
  }
};
</script>

<style>
</style>